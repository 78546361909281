import { Fade, Grid } from '@material-ui/core'
import {
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider
} from '@material-ui/core/styles'
import { Info as InfoIcon } from '@mui/icons-material'
import React, { useState, useEffect } from 'react'
import Marquee from 'react-fast-marquee'
import Button from '@material-ui/core/Button'
import Contacts from '../../components/contact'
import Sponsors from '../../components/sponsor'
import { getEnv } from '../../env'
import darkTheme from '../../theme/theme'
import { CONTACTS, SPONSORS, TITLE_SPONSOR } from '../../utils/pages'
import AboutUs from './about'
import Arrow from './arrow'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Maskgroup } from '../../assets/maskgroup.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: -1
    },
    title: {
      marginBottom: theme.spacing(4),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    para: {
      marginBottom: theme.spacing(4)
    },
    headingContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    JoinUs: {
      color: 'rgba(192, 183, 232, 1)',
      fontSize: '46px',
      lineHeight: '116.6667%',
      fontWeight: 'bold',
      fontFamily:
        'Roboto, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif'
    },
    IntoDepth: {
      color: 'white',
      fontSize: '46px',
      lineHeight: '116.6667%',
      fontWeight: 'bold'
    },
    IntoDepthLight: {
      color: 'white',
      fontSize: '46px',
      lineHeight: '116.6667%',
      fontWeight: 100,
      opacity: 0.7
    },
    textcontainer: {
      maxWidth: '540px',
      padding: '8px',
      fontSize: '16px',
      textAlign: 'center',
      marginTop: '20px'
    },
    textContainer: {
      padding: '0 20px'
    },
    image: {
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '300px'
      }
    },
    links: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      },
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
        gap: '6px'
      },
      color: theme.palette.text.primary,
      fontFamily: theme.typography.h1.fontFamily,
      '& a': { fontSize: 16 }
    }
  })
)

const Home: React.FC = () => {
  const classes = useStyles()
  return (
    <ThemeProvider theme={darkTheme}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.root}
        style={{ position: 'relative' }}
      >
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={10}
        >
          <Grid item xs={12} md={6} className={classes.textContainer}>
            <div>
              <div>
                <span className={classes.JoinUs}>Join Us</span>
                <span className={classes.IntoDepth}> Into The Depths</span>
              </div>
              <div>
                <span className={classes.IntoDepth}>Of</span>
                <span className={classes.JoinUs}> Sensors</span>
              </div>
            </div>
            <div>
              From IoT integration to emerging sensor modalities, our diverse
              program promises insightful discussions, hands-on workshops, and
              invaluable networking opportunities. Join us as we chart the
              course towards a future shaped by sensor innovation.
            </div>
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
            <img src="/homeimg.png" alt="Homeimg" className={classes.image} />
          </Grid>
        </Grid>

        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={10}
        >
          <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
            <Maskgroup className={classes.image} />
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={{ marginLeft: '12px' }}>
              <span className={classes.IntoDepth}> ABOUT </span>
              <span className={classes.IntoDepthLight}>SENSORS </span>
            </div>
            <AboutUs />
          </Grid>
        </Grid>
        {/* <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={10}
        >
          <Sponsors sponsors={SPONSORS} />
        </Grid> */}
        <Grid
          container
          item
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={10}
        >
          <Contacts contacts={CONTACTS} />
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default Home
