import { createTheme, ThemeOptions } from '@mui/material/styles'
import '@fontsource/lato'
import '@fontsource/oswald'

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    background: {
      default: '#0B0B0B'
    },
    primary: {
      main: '#0B0B0B'
    },
    secondary: {
      main: '#8D76AF'
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#C7C7C7'
    }
  },
  typography: {
    fontFamily: 'Oswald, Lato',
    h1: {
      fontFamily: 'Oswald'
    },
    body1: {
      fontFamily: 'Lato'
    }
  }
}
const darkTheme = createTheme({
  ...themeOptions
})

export default darkTheme
