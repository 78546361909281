import { Grid, ThemeProvider } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import EventCard from '../../components/cards/event'
import CircularLoader from '../../components/loaders/circular'
import PagePlaceholder, {
  PagePlaceholderProps
} from '../../components/pagePlaceholder'
import { IEvent } from '../../services/events'
import { ReactComponent as EventHeader } from '../../assets/events.svg'
import darkTheme from '../../theme/theme'
import { Timestamp } from 'firebase/firestore'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '100vh',
      height: '100%',
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'center',
      gridTemplateRows: '500px',
      padding: 25,
      alignContent: 'start',
      maxWidth: 1160,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%'
    },
    title: {
      maxHeight: 78,
      marginBottom: 30
    }
  })
)

const Events = () => {
  const classes = useStyles()
  const [userEventMap, setUserEventMap] = useState<Record<string, boolean>>({})
  const [userEventVerificationMap, setUserEventVerificationMap] = useState<
    Record<string, boolean>
  >({})
  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState<IEvent[]>([])
  const [placeholderProps, setPlaceholderProps] =
    useState<PagePlaceholderProps>({
      message: 'No events found',
      severity: 'info'
    })

  useEffect(() => {
    // Dummy event data instead of fetching from backend
    const dummyEvents: IEvent[] = [
      {
        id: '1',
        name: 'Black Box',
        description:
          'Black Box is a circuit analysis challenge where participants must analyze an unknown circuit based on its input and output characteristics. Using logical deduction and circuit analysis techniques, participants must determine the internal design of the circuit. The competition consists of multiple elimination rounds with increasing difficulty.',
        dateTime: Timestamp.fromDate(new Date(2025, 2, 8, 17, 30, 0)),
        venue: 'Orion, NIT Trichy',
        fee: 0,
        imageURL: '/blackbox.gif',
        gFormLink:
          'https://docs.google.com/forms/d/e/1FAIpQLScK2QgMxvs1eJ_6h1D8iO0YiYu4pZ3NEFLCb1xOYBWcw-LXyw/viewform?usp=sharing'
      },
      {
        id: '2',
        name: 'Circuit Mastermind',
        description:
          'Circuit Mastermind is an exciting two-round competition designed to challenge participants knowledge and practical skills in analog and digital electronics. The event consists of a quiz-based preliminary round followed by a circuit design in the final round.',
        dateTime: Timestamp.fromDate(new Date(2025, 2, 9, 16, 0, 0)),
        venue: 'Orion, NIT Trichy',
        fee: 0,
        imageURL: '/cm.png',
        gFormLink:
          'https://docs.google.com/forms/d/e/1FAIpQLSdmHgb50mOWcfzGGJ5iw-SRcyQWml8DwoE4Q8f7QO4-yU2Nsg/viewform?usp=sharing'
      },
      {
        id: '3',
        name: 'TechQuest',
        description:
          'TechQuest is a high-energy technical trivia event where participants must complete a fun mini-challenge before unlocking and answering a technical question. The event tests both technical knowledge and hands-on skills in a fast-paced, engaging format.',
        dateTime: Timestamp.fromDate(new Date(2025, 2, 7, 18, 0, 0)),
        venue: 'Orion, NIT Trichy',
        fee: 0,
        imageURL: '/techtri.jpeg',
        gFormLink:
          'https://docs.google.com/forms/d/e/1FAIpQLSfm-ggXK4UEe1KqUj6J4-8oHRqNdaWnH-uv4p4Ea1wFt0_iqg/viewform?usp=sharing'
      },
      {
        id: '4',
        name: 'Sensor Hunt',
        description:
          'Sensor Hunt is an engaging treasure hunt where participants navigate the campus to locate sensors using a series of clues. Each clue leads to a sensor, which in turn provides hints for the next location, creating a chain of challenges. Participants must interpret the clues, find the sensors, and post their discoveries in instagram before other teams to win.',
        dateTime: Timestamp.fromDate(new Date(2025, 2, 9, 8, 0, 0)),
        venue: 'Orion, NIT Trichy',
        fee: 0,
        imageURL: '/treasure.jpeg',
        gFormLink:
          'https://docs.google.com/forms/d/e/1FAIpQLSfDPToDdEmjP5iDAmP_gtVCEC83zfRrLPZidqN9zDHFfihTFw/viewform?usp=sharing'
      }
    ]

    setEvents(dummyEvents)
    setLoading(false)
  }, [])

  return (
    <ThemeProvider theme={darkTheme}>
      {loading && <CircularLoader message="Please wait while loading..." />}
      {!loading && (
        <Grid container className={classes.root}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <EventHeader style={{ height: '200px', width: '200px' }} />
          </Grid>
          {!loading && events.length === 0 && (
            <PagePlaceholder
              message={placeholderProps.message}
              severity={placeholderProps.severity}
            />
          )}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box gap="10px" display="flex" flexWrap="wrap">
              {events.map((event, index) => (
                <Grid
                  item
                  key={index}
                  justifyContent="center"
                  alignItems="center"
                >
                  <EventCard event={event} />
                </Grid>
              ))}
            </Box>
          </Grid>
        </Grid>
      )}
    </ThemeProvider>
  )
}

export default Events
