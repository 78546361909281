import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import darkTheme from '../theme/theme'
import { ThemeProvider } from '@material-ui/styles'
import ReactMarkdown from 'react-markdown'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '90%',
  overflowY: 'scroll'
}

interface IDescription {
  description: string
  open: boolean
  setOpen: (a: boolean) => void
}

const DescriptionModal: React.FC<IDescription> = (props) => {
  const handleClose = () => props.setOpen(false)

  return (
    <ThemeProvider theme={darkTheme}>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Description
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <ReactMarkdown>{props.description}</ReactMarkdown>
          </Typography>
        </Box>
      </Modal>
    </ThemeProvider>
  )
}

export default DescriptionModal
