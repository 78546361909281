import { FirebaseOptions, initializeApp } from 'firebase/app'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getEnv, ReactAppEnv } from '../env'

const firebaseConfig: FirebaseOptions = {
  apiKey: getEnv({ key: 'REACT_APP_FIREBASE_API_KEY' }),
  authDomain: getEnv({ key: 'REACT_APP_FIREBASE_AUTH_DOMAIN' }),
  projectId: getEnv({ key: 'REACT_APP_FIREBAE_PROJECT_ID' }),
  storageBucket: getEnv({ key: 'REACT_APP_FIREBASE_STORAGE_BUCKET' }),
  messagingSenderId: getEnv({ key: 'REACT_APP_FIREBASE_MESSAGING_SENDER_ID' }),
  appId: getEnv({ key: 'REACT_APP_FIREBASE_APP_ID' }),
  measurementId: getEnv({ key: 'REACT_APP_FIREBASE_MEASUREMENT_ID' })
}

const app = initializeApp(firebaseConfig)

const db = getFirestore()

const auth = getAuth()

export { app, db, auth }
export {}
