export interface IContact {
  name: string
  role: string
  mobile: string
  email: string
  imageURL: string
}

export interface ISponsor {
  name: string
  imageURL: string
  url?: string
  partnership?: string
}

export interface ITeam {
  name: string
  imageURL: string
  url?: string
  partnership?: string
}

const SPONSORS_IMAGE_BASE_PATH = '/sponsors'

const getSponsorImageURL = ({ fileName }: { fileName: string }) =>
  `${SPONSORS_IMAGE_BASE_PATH}/${fileName}`

export const TITLE_SPONSOR = 'https://www.gailonline.com/'

export const SPONSORS: ISponsor[] = [
  {
    name: 'ACC',
    imageURL: '/acc.png',
    url: 'https://www.acclimited.com/',
    partnership: 'Construction Partner'
  },
  {
    name: 'Bosch',
    imageURL: '/bosch.png',
    url: 'https://www.bosch.com/',
    partnership: 'Engineering Partner'
  },
  {
    name: 'Cuephoria',
    imageURL: '/cuephoria.jpeg',
    url: 'https://www.cuephoriabilliards.com/',
    partnership: 'Gaming Partner'
  },
  {
    name: 'Furnicheer',
    url: 'https://furnicheer.com/?srsltid=AfmBOoqbgyNLMPxYvsa7GTM5VVVICnRLeznnfDlSyA189jC3spBLhJ_M',
    imageURL: '/furnicheer.png',
    partnership: 'Creative Partner'
  },
  {
    name: 'Gail',
    url: 'https://gailonline.com/',
    imageURL: '/gail.jpg',
    partnership: 'Title Partner'
  },
  {
    name: 'H.V Technology',
    url: 'https://www.hvtechnology.in/',
    imageURL: '/hv.png',
    partnership: 'Digital Partner'
  },
  {
    name: 'Loonycorn',
    url: 'https://loonycorn.com/',
    imageURL: '/loony.jpeg',
    partnership: 'Learning Partner'
  },
  {
    name: 'Raptee',
    url: 'https://rapteehv.com/',
    imageURL: '/raptee.png',
    partnership: 'Tech Partner'
  }
]

const EVENTS_IMAGE_BASE_PATH = '/events'

export const getEventImageURL = ({ fileName }: { fileName: string }) =>
  `${EVENTS_IMAGE_BASE_PATH}/${fileName}`

const CONTACTS_IMAGE_BASE_PATH = '/contacts'

export const getContactImageURL = ({ fileName }: { fileName: string }) =>
  `${CONTACTS_IMAGE_BASE_PATH}/${fileName}`

export const CONTACTS: IContact[] = [
  {
    name: 'Boomika V',
    email: 'boomikanitt@gmail.com',
    mobile: '+91 7708164749',
    role: 'Public Relations Head',
    imageURL: '/pr1.jpeg'
  },
  {
    name: 'Rakesh Aravindha',
    email: 'rakesharavindha@gmail.com',
    mobile: '+91 9849165799',
    role: 'Public Relations Head',
    imageURL: '/pr2.jpg'
  }
]
