import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import Events from './pages/events'
import Home from './pages/home'
import Workshops from './pages/workshops'
import darkTheme from './theme/theme'

import './App.css'
import Navbar from './components/navbar/navbar'
import Sponsors from './components/sponsor/index'
import Contact from './pages/Contact'
import Footer from './pages/footer/index'
import GuestLectures from './pages/guestLectures'
import PrivacyPolicy from './pages/PrivacyPolicy'
import RefundsAndCancellation from './pages/RefundsAndCancellation'
import TermsAndConditions from './pages/TermsAndConditions'
import { SPONSORS } from './utils/pages'

const router = createBrowserRouter([
  { path: '/', element: <Home /> },
  { path: '/events', element: <Events /> },
  { path: '/guest-lectures', element: <GuestLectures /> },
  { path: '/workshops', element: <Workshops /> },
  { path: '/contact', element: <Contact /> },
  { path: '/privacy-policy', element: <PrivacyPolicy /> },
  { path: '/refunds-and-cancellation', element: <RefundsAndCancellation /> },
  { path: '/terms-and-conditions', element: <TermsAndConditions /> }
])

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <div>
        <CssBaseline />
        <Navbar />
        <RouterProvider router={router} />
        <div
          style={{ height: 'auto', display: 'flex', justifyContent: 'center' }}
        >
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            xs={12}
            md={10}
          >
            <Sponsors sponsors={SPONSORS} />
          </Grid>
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default App
