import { Box, Typography } from '@material-ui/core'
import {
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider
} from '@material-ui/core/styles'
import React from 'react'
import darkTheme from '../../theme/theme'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'flex-start',
      zIndex: -1,
      margin: theme.spacing(2),
      flexDirection: 'column'
    },
    title: {
      maxHeight: '50px'
    },
    para: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
      textAlign: 'start',
      fontFamily: theme.typography.body1.fontFamily
    }
  })
)

const AboutUs: React.FC = () => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={darkTheme}>
      <Box className={classes.root}>
        <Typography variant="body1" align="center" className={classes.para}>
          Sensors, the national level technical symposium of the Instrumentation
          and Control Engineering department of NIT Trichy, is undoubtedly the
          jewel in the crown of the department. Sensors has a long and
          distinguished history dating back to 1997 when the staff and the
          students felt the need for a forum where the academicians and industry
          can converge to discuss the latest trends in the field of
          Instrumentation and Control. The essence of Sensors is to transcend
          classrooms and facilitate learning through informative guest lectures
          from eminent personalities, hands-on workshops to give students a feel
          for the subjects and innovative and challenging events that pit some
          of the brightest minds in the country against one another.
          <br></br>
          <br></br>
          Another aspect of this prestigious event is that all the students of
          the department come forward enthusiastically to organize the event
          thus fostering teamwork, creativity and management skills in the
          technocrats of tomorrow. The previous edition held in March 2024 saw a
          multitude of new events, a plethora of workshops being organized. As
          the previous edition turned out to be a humongous success, the
          expectations among the student and the teaching fraternity have
          exceeded to an unexpected high for the next edition of Sensors.
        </Typography>
      </Box>
    </ThemeProvider>
  )
}

export default AboutUs
