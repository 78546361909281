import { Container } from '@mui/material'

const RefundsAndCancellation = () => {
  return (
    <Container>
      <h1>Refund Policy</h1>
      <h3>Returns</h3>
      Our policy lasts 30 days. If 30 days have gone by since your t-shirt
      purchase, unfortunately we can’t offer you a refund or exchange.
      <br />
      To be eligible for a t-shirt return, your t-shirt must be unused and in
      the same condition that you received it. It must also be in the original
      packaging.
      <br />
      To complete your t-shirt return, we require a receipt or proof of
      purchase.
      <br />
      Please do not send your purchase back to the manufacturer.
      <br />
      <h3>Refunds (if applicable)</h3>
      Once your return is received and inspected, we will send you an email to
      notify you that we have received your returned item. We will also notify
      you of the approval or rejection of your refund. If you are approved, then
      your refund will be processed, and a credit will automatically be applied
      to your credit card or original method of payment, within 5-7 working
      days.
      <br />
      <br />
      <h3>Late or missing refunds (if applicable)</h3>
      If you haven’t received a refund yet, first check your bank account again.
      Then contact your credit card company, it may take some time before your
      refund is officially posted. Next contact your bank. There is often some
      processing time before a refund is posted. If you’ve done all of this and
      you still have not received your refund yet, please contact us at
      mahikaarunkumar@gmail.com.
      <br />
      <br />
      <h3>Shipping</h3>
      To return your product, you should mail your product to: NIT Trichy,
      Tiruchirappalli, Tamil Nadu, India - 620015.
      <br />
      You will be responsible for paying for your own shipping costs for
      returning your item. Shipping costs are non-refundable. If you receive a
      refund, the cost of return shipping will be deducted from your refund.
      <br />
      Depending on where you live, the time it may take for your exchanged
      product to reach you, may vary.
    </Container>
  )
}

export default RefundsAndCancellation
