import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { Info, Warning as WarningIcon } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import { Notice, NoticeType } from '../../services/notices'
import darkTheme from '../../theme/theme'
import NavDrawer from './drawer'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    display: 'flex'
  },
  links: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      gap: '6px'
    },
    color: theme.palette.text.primary,
    fontFamily: theme.typography.h1.fontFamily,
    '& a': { fontSize: 16 }
  },
  ham: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  logo: {
    marginRight: 5,
    width: '80px',
    height: '100%'
  },
  regOpen: {
    color: '#8D76AF'
  },
  noticeContent: {
    display: 'contents'
  }
}))

const Navbar = () => {
  const [notices, setNotices] = useState<
    { message: string; type: NoticeType }[]
  >([])
  const classes = useStyles()

  const getNotice = ({
    message,
    type
  }: {
    message: string
    type: NoticeType
  }) => {
    switch (type) {
      case NoticeType.INFO:
        return (
          <div className={classes.noticeContent}>
            <Info sx={{ marginRight: 1, marginLeft: 6 }} />
            <span>{message}</span>
          </div>
        )
      case NoticeType.WARNING:
        return (
          <div className={classes.noticeContent}>
            <WarningIcon
              htmlColor="yellow"
              sx={{ marginRight: 1, marginLeft: 6 }}
            />
            <span>{message}</span>
          </div>
        )
      case NoticeType.URGENT:
        return (
          <div className={classes.noticeContent}>
            <WarningIcon
              htmlColor="red"
              sx={{ marginRight: 1, marginLeft: 6 }}
            />
            <span>{message}</span>
          </div>
        )
      default:
        break
    }
  }

  useEffect(() => {
    Notice.getNotices().then((notices) => setNotices(notices))
  }, [])

  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar position="static">
        <Toolbar
          style={{
            padding: '15px 25px 15px 25px',
            maxWidth: 1160,
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%'
          }}
        >
          <Typography variant="h6" className={classes.title}>
            <img
              onClick={() => (window.location.href = '/')}
              src={'/sensors_logo_web.png'}
              height="30"
              className={classes.logo}
              style={{ cursor: 'pointer' }}
            />
          </Typography>
          <div className={classes.links}>
            <Button component="a" href="/">
              Home
            </Button>
            <Button component="a" href="/events">
              Events
            </Button>
            <Button component="a" href="/workshops">
              Workshops
            </Button>
            <Button component="a" href="/guest-lectures">
              Guest Lectures
            </Button>
            <Button component="a" href="https://linktr.ee/sensors2025">
              Blog
            </Button>
          </div>
          <div className={classes.ham}>
            <NavDrawer />
          </div>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  )
}
export default Navbar
