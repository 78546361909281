export enum ReactAppEnv {
  DEV = 'dev',
  PROD = 'prod'
}

interface ReactAppFlagEnvs {
  REACT_APP_DISABLE_LOGIN: string
  REACT_APP_DISABLE_EVENT_REG: string
  REACT_APP_DISABLE_STORE_REG: string
  REACT_APP_DISABLE_WORKSHOP_REG: string
  REACT_APP_ALL_REG_OPEN: string
}

interface DefaultEnvs {
  REACT_APP_MODE: ReactAppEnv
}

interface ReactAppRazorpayEnvs {
  REACT_APP_RAZORPAY_KEY_ID: string
  REACT_APP_CREATE_ORDER: string
  REACT_APP_VERIFY_PAYMENT: string
}

interface ReactAppSentryEnvs {
  REACT_APP_SENTRY_DSN: string
}

interface ReactAppFirebaseEnvs {
  REACT_APP_FIREBASE_API_KEY: string
  REACT_APP_FIREBASE_AUTH_DOMAIN: string
  REACT_APP_FIREBASE_APP_ID: string
  REACT_APP_FIREBASE_STORAGE_BUCKET: string
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: string
  REACT_APP_FIREBAE_PROJECT_ID: string
  REACT_APP_FIREBASE_MEASUREMENT_ID: string
}

interface ReactAppLocalFirebaseEnvs {
  REACT_APP_LOCAL_FUNCTIONS_URL: string
  REACT_APP_LOCAL_FUNCTIONS_HOST: number
}

interface ReactAppEnvs
  extends DefaultEnvs,
    ReactAppFirebaseEnvs,
    ReactAppLocalFirebaseEnvs,
    ReactAppRazorpayEnvs,
    ReactAppSentryEnvs,
    ReactAppFlagEnvs {}

export const getEnv = ({ key }: { key: keyof ReactAppEnvs }) => process.env[key]
