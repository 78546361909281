import {
  CardContent,
  Grid,
  Paper,
  ThemeProvider,
  Typography
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Button } from '@mui/material'
import { useState } from 'react'
import { DEFAULT_POPUP } from '../../constants/ui'
import { IEvent } from '../../services/events'
import darkTheme from '../../theme/theme'
import Popup, { IPopup } from '../alert/popup'
import DescriptionModal from '../descriptionModal'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 360,
      padding: theme.spacing(1),
      backgroundColor: '#1E1E1E',
      height: 500,
      marginBottom: 15
    },
    media: { height: '200px', width: '100%', objectFit: 'cover' },
    mediaContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0px !important'
    },
    content: {
      flexGrow: 1,
      textAlign: 'start',
      padding: '10px 15px 10px 15px !important'
    },
    title: {
      marginBottom: theme.spacing(1),
      wordWrap: 'break-word',
      fontSize: 24,
      lineHeight: '36px',
      textTransform: 'uppercase'
    },
    description: {
      color: theme.palette.text.secondary,
      wordWrap: 'break-word',
      marginTop: 16,
      marginBottom: 20,
      fontSize: 16,
      lineHeight: '19px'
    },
    feePlaceholder: {
      color: theme.palette.text.secondary,
      fontSize: 14
    },
    fee: {
      fontSize: 20,
      lineHeight: '22px',
      fontWeight: 700,
      color: theme.palette.secondary.main
    },
    dateTimeVenue: {
      color: theme.palette.secondary.main,
      fontSize: 14,
      lineHeight: '17px'
    }
  })
)

interface Props {
  event: IEvent
}

const EventCard: React.FC<Props> = ({ event }) => {
  const classes = useStyles()
  const [popup, setPopup] = useState<IPopup>(DEFAULT_POPUP)
  const [readmore, setReadMore] = useState(false)
  const [description, setDescription] = useState('')

  const handleRedirect = () => {
    const link =
      event.gFormLink && event.gFormLink.trim() !== ''
        ? event.gFormLink
        : 'https://example.com'
    window.open(link, '_blank')
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Popup {...popup}></Popup>
      <DescriptionModal
        description={description}
        open={readmore}
        setOpen={setReadMore}
      ></DescriptionModal>
      <Paper elevation={12} className={classes.card}>
        <Grid container spacing={2} alignItems="center">
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              overflow: 'hidden'
            }}
            className={classes.mediaContainer}
          >
            <img
              className={classes.media}
              src={event.imageURL}
              alt={event.name}
              style={{
                width: '100%',
                height: '220px',
                objectFit: 'cover' // Ensures the image fits without distortion
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CardContent className={classes.content}>
              <Typography variant="h4" component="h2" className={classes.title}>
                {event.name}
              </Typography>
              <Typography className={classes.dateTimeVenue}>
                {event.dateTime
                  .toDate()
                  .toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}{' '}
                | {event.venue}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                className={classes.description}
              >
                {event.description.slice(0, 80)}
                <Button
                  onClick={() => {
                    setReadMore(true)
                    setDescription(event.description)
                  }}
                  style={{
                    textTransform: 'none',
                    fontFamily: 'Lato',
                    padding: '0px',
                    marginLeft: 4
                  }}
                  color="secondary"
                  variant="text"
                >
                  read more
                </Button>
              </Typography>

              <Grid container alignItems="flex-end">
                <Grid item xs={8}>
                  <Typography>
                    <div className={classes.feePlaceholder}>
                      Registration Fee
                    </div>
                    <div className={classes.fee}>
                      {event.fee > 0 ? `INR ${event.fee}` : 'Free'}
                    </div>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    onClick={handleRedirect} // ✅ Uses event.gFormLink
                    variant="contained"
                    color="secondary"
                  >
                    Register
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  )
}

export default EventCard
