import {
  DocumentData,
  Firestore,
  CollectionReference,
  collection,
  getDocs
} from 'firebase/firestore'
import { ECollections } from '../constants/services'
import { db } from '../firebase/initialize'
import { IBase } from './base'

export enum NoticeType {
  INFO = 'info',
  URGENT = 'urgent',
  WARNING = 'warning'
}

export interface INotices extends IBase {
  messages: string[]
}

export interface INotice extends IBase {
  message: string
  type: NoticeType
}

export interface INoticesDoc extends DocumentData, INotices {}

export class Notice {
  private static db: Firestore = db
  private static collectionName: string = ECollections.NOTICE
  private static collectionRef: CollectionReference<INoticesDoc> = collection(
    this.db,
    this.collectionName
  ) as CollectionReference<INoticesDoc>

  notice: INotice = {
    message: '',
    type: NoticeType.INFO,
    id: ''
  }

  constructor({ notice }: { notice: INotice }) {}
  // const noticesSnap = (await getDocs(this.collectionRef)).docs

  static async getNotices(): Promise<INotice[]> {
    const noticesSnap = [
      {
        id: '1',
        data: () => ({
          messages: ['Message 1', 'Message 2']
        })
      }
    ]

    const notices: INotice[] = []

    noticesSnap.forEach((noticeSnap) => {
      const data = noticeSnap.data()

      notices.push(
        ...data.messages.map((message) => ({
          type: noticeSnap.id as NoticeType,
          message,
          id: noticeSnap.id
        }))
      )
    })

    return notices
  }
}
