import React from 'react'
import darkTheme from '../theme/theme'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@mui/material'
import { ThemeProvider, Theme } from '@mui/material/styles'
import {
  Warning as WarningIcon,
  Error as ErrorIcon,
  Info as InfoIcon
} from '@mui/icons-material'
import { SeverityLevel } from '@sentry/react'

export interface PagePlaceholderProps {
  message: string
  severity: SeverityLevel
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxContainer: {
      display: 'flex',
      justifyContent: 'center',
      height: '60vh',
      alignItems: 'center',
      fontSize: '20px'
    },
    placeHolderMessage: {
      fontSize: '20px !important',
      padding: theme.spacing(1)
    }
  })
)

const PagePlaceholder: React.FC<PagePlaceholderProps> = (props) => {
  const classes = useStyles()

  const getIconBySeverity = ({ severity }: { severity: SeverityLevel }) => {
    switch (severity) {
      case 'error':
        return <ErrorIcon fontSize="large" htmlColor="red" />

      case 'warning':
        return <WarningIcon fontSize="large" htmlColor="yellow" />

      default:
        return <InfoIcon fontSize="large" htmlColor="blue" />
    }
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Box className={classes.boxContainer}>
        {getIconBySeverity({ severity: props.severity })}
        <Typography className={classes.placeHolderMessage}>
          {props.message}
        </Typography>
      </Box>
    </ThemeProvider>
  )
}

export default PagePlaceholder
