import { Paper, Typography } from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { Box } from '@mui/material'
import React from 'react'
import Marquee from 'react-fast-marquee'
import darkTheme from '../../theme/theme'
import { ISponsor } from '../../utils/pages'
import { ReactComponent as SponsorHeader } from '../../assets/sponsors.svg'

interface SponsorsProps {
  sponsors: ISponsor[]
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start'
  },
  paper: {
    display: 'inline-block',
    margin: theme.spacing(0, 2),
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  sponsorImage: { height: '140px' },
  title: {
    maxHeight: '50px'
  }
}))

const Sponsors: React.FC<SponsorsProps> = ({ sponsors }) => {
  const classes = useStyles()
  return (
    <ThemeProvider theme={darkTheme}>
      <Box className={classes.root}>
        <Typography variant="h3" align="center" gutterBottom>
          <SponsorHeader style={{ height: '200px', width: '200px' }} />
        </Typography>
        <Marquee speed={50} gradient={false}>
          {sponsors.map((sponsor, index) => (
            <Paper className={classes.paper} key={index}>
              {sponsor.partnership && (
                <Typography
                  onClick={() => window.open(sponsor.url ?? '')}
                  variant="h6"
                  align="center"
                  style={{ color: 'white' }}
                  gutterBottom
                >
                  {sponsor.partnership.toUpperCase()}
                </Typography>
              )}
              <img
                onClick={() => window.open(sponsor.url ?? '')}
                className={classes.sponsorImage}
                src={sponsor.imageURL}
                alt={sponsor.name}
                height="100%"
              />
            </Paper>
          ))}
        </Marquee>
      </Box>
    </ThemeProvider>
  )
}

export default Sponsors

// import { Paper, Typography } from '@material-ui/core'
// import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
// import { Box } from '@mui/material'
// import React from 'react'
// import Marquee from 'react-fast-marquee'
// import darkTheme from '../../theme/theme'
// import { ISponsor } from '../../utils/pages'
// import { ReactComponent as SponsorHeader } from '../../assets/sponsors.svg'

// interface SponsorsProps {
//   sponsors: ISponsor[]
// }

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     overflow: 'hidden',
//     whiteSpace: 'nowrap',
//     margin: theme.spacing(2),
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'start'
//   },
//   paper: {
//     display: 'inline-block',
//     margin: theme.spacing(0, 2),
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary
//   },
//   sponsorImage: { height: '140px' },
//   title: {
//     maxHeight: '50px'
//   }
// }))

// const Sponsors: React.FC<SponsorsProps> = ({ sponsors }) => {
//   const classes = useStyles()
//   return (
//     <ThemeProvider theme={darkTheme}>
//       <Box className={classes.root}>
//         <Typography variant="h3" align="center" gutterBottom>
//           <SponsorHeader style={{ height: '200px', width: '200px' }} />
//         </Typography>
//         <Marquee pauseOnHover={true} speed={50} gradient={false}>
//           {sponsors.map((sponsor, index) => (
//             <Paper className={classes.paper} key={index}>
//               {sponsor.partnership && (
//                 <Typography onClick={() => window.open(sponsor.url ?? '')} variant="h6" align="center" gutterBottom>
//                   {sponsor.partnership.toUpperCase()}
//                 </Typography>
//               )}
//               <img
//                 onClick={() => window.open(sponsor.url ?? '')}
//                 className={classes.sponsorImage}
//                 src={sponsor.imageURL}
//                 alt={sponsor.name}
//                 height="100%"
//               />
//             </Paper>
//           ))}
//         </Marquee>
//       </Box>
//     </ThemeProvider>
//   )
// }

// export default Sponsors
