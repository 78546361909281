import { Grid, ThemeProvider } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Box } from '@mui/material'
import { useState } from 'react'
import WorkshopCard from '../../components/cards/workshop'
import PagePlaceholder from '../../components/pagePlaceholder'
import darkTheme from '../../theme/theme'
import { ReactComponent as WorkshopHeader } from '../../assets/workshops.svg'
import { Timestamp } from 'firebase/firestore'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '100vh',
      height: '100%',
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'center',
      padding: 25,
      alignContent: 'start',
      maxWidth: 1160,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%'
    },
    title: {
      maxHeight: 78,
      marginBottom: 30
    }
  })
)

// Dummy workshop data
const dummyWorkshops = [
  {
    id: '1',
    name: 'Mastering PCB Design By RapteeHV',
    dateTime: new Date(2025, 2, 8, 10, 0, 0),
    venue: 'In-Person',
    description:
      'This hands-on PCB Design workshop provides in-depth training on designing robust and manufacturable circuit boards using industry-standard EDA tools. Participants will learn schematic capture, PCB layout, design rule checks (DRC), signal integrity, and thermal management to optimize board performance. The session includes practical exercises, real-world case studies, and expert guidance to enhance design efficiency. Attendees will also explore advanced layout techniques, high-speed design principles, and manufacturability constraints, bridging the gap between theory and real-world implementation.',
    fee: 200,
    imageURL: '/raptee.png',
    gFormLink: 'https://forms.gle/UiaHeHVNEXGsAWor8'
  },
  {
    id: '2',
    name: 'A Hands-On Introduction to Sensor Applications',
    dateTime: new Date(2025, 2, 8, 14, 0, 0),
    venue: 'In-Person',
    description:
      'This hands-on workshop provides comprehensive training on sensor applications within smart systems. Participants will explore the pivotal role of sensors, analog frontends, and embedded processors that power modern smart appliances. The session emphasizes practical experimentation through brainstorming and tinkering with sensor modules, enabling an in-depth understanding of data acquisition, signal processing, and integration of machine learning and AI techniques. Real-world case studies and guided exercises will bridge the gap between theoretical concepts and practical implementation.',
    fee: 150,
    imageURL: '/introGL.png',
    gFormLink: 'https://forms.gle/UiaHeHVNEXGsAWor8'
  },
  {
    id: '3',
    name: 'Design of Controllers for SMPC using MATLAB By Bosch',
    dateTime: new Date(2025, 2, 9, 10, 0, 0),
    venue: 'In-Person',
    description:
      'This workshop provides an in-depth understanding of Switched-Mode Power Converters (SMPCs) and their applications in various fields, including electric vehicles (EVs) and isolated converters. Participants will engage in designing, modeling, and simulating boost converters in Continuous Conduction Mode (CCM) while exploring different mathematical and averaged models. The workshop also includes closed-loop control techniques, transfer function derivation using state-space averaging, and stability analysis to ensure efficient system performance. Advanced topics such as controller tuning using frequency analysis, auto-tuning in MATLAB, cascade controllers, peak current mode, and hysteresis loop controllers will also be explored.',
    fee: 300,
    imageURL: '/bosch.png',
    gFormLink: 'https://forms.gle/UiaHeHVNEXGsAWor8'
  }
]

const Workshops = () => {
  const classes = useStyles()
  const [workshops, setWorkshops] = useState(dummyWorkshops)

  return (
    <ThemeProvider theme={darkTheme}>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <WorkshopHeader style={{ height: '200px', width: '220px' }} />
        </Grid>
        {workshops.length === 0 && (
          <PagePlaceholder message="No workshops found" severity="info" />
        )}
        <Grid item xs={12}>
          <Box gap="10px" display="flex" flexWrap="wrap">
            {workshops.map((workshop) => (
              <Grid item key={workshop.id}>
                <WorkshopCard workshop={workshop} />
              </Grid>
            ))}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}

export default Workshops
