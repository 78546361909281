export enum ECollections {
  USERS = 'users',
  EVENTS = 'events',
  WORKSHOPS = 'workshops',
  ARTICLES = 'articles',
  STORE = 'store',
  GUEST_LECTURE = 'guestLectures',
  WEBINARS = 'webinars',
  NOTICE = 'notice'
}

export enum EPaymentOrderGroups {
  EVENTS = 'events',
  WORKSHOPS = 'workshops',
  STORE = 'store'
}
