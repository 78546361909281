import { Container } from '@mui/material'

const Contact = () => {
  return (
    <Container maxWidth="lg">
      <h1>CONTACT</h1>
      <h3>Address</h3>
      NIT Trichy, Tiruchirappalli, Tamil Nadu, India - 620015
      <br />
      <br />
      <h3>Contact</h3>
      <a
        style={{ textDecoration: 'none', color: '#ffffff' }}
        href="mailto:mahikaarunkumar@gmail.com"
      >
        mahikaarunkumar@gmail.com
      </a>
    </Container>
  )
}

export default Contact
