import {
  CardContent,
  Grid,
  Paper,
  ThemeProvider,
  Typography,
  Button
} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { useState } from 'react'
import darkTheme from '../../theme/theme'
import Popup, { IPopup } from '../alert/popup'
import DescriptionModal from '../descriptionModal'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 360,
      padding: theme.spacing(1),
      backgroundColor: '#1E1E1E',
      maxHeight: 700,
      marginBottom: 15
    },
    media: { height: '300px', width: '100%', objectFit: 'contain' },
    content: {
      flexGrow: 1,
      textAlign: 'start',
      padding: '10px 15px 10px 15px !important'
    },
    title: {
      marginBottom: theme.spacing(1),
      wordWrap: 'break-word',
      fontSize: 24,
      lineHeight: '36px',
      textTransform: 'uppercase'
    },
    description: {
      color: theme.palette.text.secondary,
      wordWrap: 'break-word',
      marginTop: 16,
      marginBottom: 20,
      fontSize: 16,
      lineHeight: '19px'
    },
    feePlaceholder: {
      color: theme.palette.text.secondary,
      fontSize: 14
    },
    fee: {
      fontSize: 20,
      lineHeight: '22px',
      fontWeight: 700,
      color: theme.palette.secondary.main
    },
    dateTimeVenue: {
      color: theme.palette.text.secondary,
      fontSize: 14,
      marginBottom: theme.spacing(1)
    }
  })
)

interface Props {
  workshop: {
    id: string
    name: string
    dateTime: Date
    venue: string
    description: string
    fee: number
    imageURL: string
    gFormLink: string
  }
}

const WorkshopCard: React.FC<Props> = ({ workshop }) => {
  const classes = useStyles()
  const [popup, setPopup] = useState<IPopup>({
    open: false,
    message: '',
    severity: 'info',
    onClose: () => setPopup((prev) => ({ ...prev, open: false }))
  })
  const [readmore, setReadMore] = useState(false)
  const [description, setDescription] = useState('')

  const handleRegister = () => {
    window.open(workshop.gFormLink, '_blank')
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Popup {...popup} />
      <DescriptionModal
        description={description}
        open={readmore}
        setOpen={setReadMore}
      />
      <Paper elevation={12} className={classes.card}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <img
              className={classes.media}
              src={workshop.imageURL}
              alt={workshop.name}
            />
          </Grid>
          <Grid item xs={12}>
            <CardContent className={classes.content}>
              <Typography variant="h4" component="h2" className={classes.title}>
                {workshop.name}
              </Typography>
              <Typography className={classes.dateTimeVenue}>
                {workshop.dateTime.toLocaleString('en-IN', {
                  timeZone: 'Asia/Kolkata'
                })}{' '}
                | {workshop.venue}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                className={classes.description}
              >
                {workshop.description.slice(0, 80)}
                <Button
                  onClick={() => {
                    setReadMore(true)
                    setDescription(workshop.description)
                  }}
                  style={{
                    textTransform: 'none',
                    padding: '0px',
                    marginLeft: 4
                  }}
                  color="secondary"
                  variant="text"
                >
                  read more
                </Button>
              </Typography>
              <Grid container alignItems="flex-end">
                <Grid item xs={8}>
                  <Typography>
                    <div className={classes.feePlaceholder}>
                      Registration Fee
                    </div>
                    <div className={classes.fee}>
                      {workshop.fee > 0 ? `INR ${workshop.fee}` : 'Free'}
                    </div>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    onClick={handleRegister}
                    variant="contained"
                    color="secondary"
                  >
                    Register
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </Paper>
    </ThemeProvider>
  )
}

export default WorkshopCard
