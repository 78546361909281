import { Grid, ThemeProvider } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Box } from '@mui/material'
import { useState } from 'react'
import GuestLectureCard from '../../components/cards/guestLecture'
import PagePlaceholder from '../../components/pagePlaceholder'
import darkTheme from '../../theme/theme'
import { ReactComponent as GLHeader } from '../../assets/guestlectures.svg'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '100vh',
      height: '100%',
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'center',
      padding: 25,
      alignContent: 'start',
      maxWidth: 1300,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%'
    },
    title: {
      maxHeight: 78,
      marginBottom: 30,
      maxWidth: 406.78,
      width: '100%'
    }
  })
)

// Define an interface for guest lectures
interface IGuestLecture {
  id: string
  topic: string
  dateTime: string
  venue: string
  duration: string
  guestName: string
  imageURL: string
  gFormLink: string
}

// Define dummy guest lectures data
const dummyGuestLectures: IGuestLecture[] = [
  {
    id: '1',
    topic: 'IT and OT convergence trends',
    dateTime: '2025-03-07T17:00:00',
    venue: 'EEE Auditorium',
    duration: '2 hours',
    guestName: 'Mr. P.S.Krishna',
    imageURL: '/itot.jpeg',
    gFormLink: 'https://forms.example.com/register-ai'
  },
  {
    id: '2',
    topic:
      'Natural Language Processing and its role in AI driven language understanding',
    dateTime: '2025-03-08T09:00:00',
    venue: 'GJCH Conference Hall-2',
    duration: '1.5 hours',
    guestName: 'By S Jayakumar',
    imageURL: '/NLP.jpeg',
    gFormLink: 'https://forms.example.com/register-quantum'
  },
  {
    id: '3',
    topic: 'Embedded Electronics',
    dateTime: '2025-03-09T17:30:00',
    venue: 'GJCH Conference Hall-2',
    duration: '1.5 hours',
    guestName: 'By Balajee Seshadri',
    imageURL: '/EE.jpeg',
    gFormLink: 'https://forms.example.com/register-quantum'
  }
]

const GuestLectures = () => {
  const classes = useStyles()
  const [guestLectures] = useState<IGuestLecture[]>(dummyGuestLectures)

  return (
    <ThemeProvider theme={darkTheme}>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <GLHeader style={{ height: '200px', width: '250px' }} />
        </Grid>

        {guestLectures.length === 0 ? (
          <PagePlaceholder message="No guest lectures found" severity="info" />
        ) : (
          <Grid item xs={12}>
            <Box gap="10px" display="flex" flexWrap="wrap">
              {guestLectures.map((guestLecture, index) => (
                <Grid item key={index}>
                  <GuestLectureCard guestLecture={guestLecture} />
                </Grid>
              ))}
            </Box>
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  )
}

export default GuestLectures
