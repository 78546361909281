import { ThemeProvider } from '@material-ui/core/styles'
import darkTheme from '../../theme/theme'
import React from 'react'
import {
  CircularProgress,
  Box,
  makeStyles,
  createStyles,
  Theme
} from '@material-ui/core'

interface Props {
  message?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootBox: {
      display: 'flex',
      justifyContent: 'center',
      height: '90vh',
      alignItems: 'center',
      fontSize: '20px'
    },
    progress: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    progressIcon: {
      color: theme.palette.secondary.main
    }
  })
)

const CircularLoader: React.FC<Props> = (props) => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={darkTheme}>
      <Box className={classes.rootBox}>
        <div className={classes.progress}>
          <CircularProgress className={classes.progressIcon} />
          <span style={{ padding: '10px' }}>{props.message || ''}</span>
        </div>
      </Box>
    </ThemeProvider>
  )
}

export default CircularLoader
