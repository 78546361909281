import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import { ThemeProvider } from '@material-ui/core/styles'
import darkTheme from '../../theme/theme'
import { Alert, AlertProps } from '@mui/material'

export interface IPopup {
  message: string
  open: boolean
  onClose: () => void
  severity: AlertProps['severity']
}

const Popup: React.FC<IPopup> = (props) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={props.open}
        onClose={props.onClose}
        message="I love snacks"
      >
        <Alert onClose={props.onClose} severity={props.severity}>
          {props.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  )
}

export default Popup
