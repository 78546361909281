import { Grid, Paper, Typography, Button } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(2),
      maxWidth: 400,
      height: 400
    },
    media: {
      width: '100%',
      height: 200,
      objectFit: 'cover'
    },
    title: {
      fontWeight: 'bold'
    },
    description: {
      color: 'gray'
    },
    dateTimeVenue: {
      marginTop: theme.spacing(1),
      fontStyle: 'italic'
    },
    registerButton: {
      marginTop: theme.spacing(2)
    }
  })
)

// Define an interface for guest lecture props
interface IGuestLecture {
  id: string
  topic: string
  dateTime: string
  venue: string
  duration: string
  guestName: string
  imageURL: string
  gFormLink: string
}

interface Props {
  guestLecture: IGuestLecture
}

const GuestLectureCard: React.FC<Props> = ({ guestLecture }) => {
  const classes = useStyles()

  return (
    <Paper elevation={12} className={classes.card}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <img
            className={classes.media}
            src={
              guestLecture.imageURL || '/guestLectures/default-guestLecture.jpg'
            }
            alt={guestLecture.topic}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.title}>
            {guestLecture.topic}
          </Typography>
          <Typography variant="body2" className={classes.dateTimeVenue}>
            {new Date(guestLecture.dateTime).toLocaleString()} |{' '}
            {guestLecture.venue}
          </Typography>
          <Typography variant="body1" component="p">
            Guest Speaker: {guestLecture.guestName}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default GuestLectureCard
