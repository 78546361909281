import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import {
  LibraryBooksOutlined as LibraryBooksOutlinedIcon,
  StoreOutlined as StoreOutlinedIcon
} from '@mui/icons-material'
import ArticleIcon from '@mui/icons-material/Article'
import DomainVerificationIcon from '@mui/icons-material/DomainVerification'
import HomeIcon from '@mui/icons-material/Home'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import StorefrontIcon from '@mui/icons-material/Storefront'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import React, { useState } from 'react'
import { getEnv } from '../../env'
// import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  menuButton: {},
  drawer: {
    width: 250,
    flexShrink: 0
  },
  drawerPaper: {
    width: 250
  },
  drawerContainer: {
    overflow: 'auto',
    fontFamily: theme.typography.h1.fontFamily
  },
  listItemText: {
    fontFamily: `${theme.typography.h1.fontFamily} !important`
  }
}))

const NavDrawer = () => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  React.useEffect(() => {
    const handleWindowClick = (event: MouseEvent) => {
      if (
        event.target instanceof HTMLElement &&
        !event.target.closest('.MuiDrawer-root') &&
        !event.target.closest('.MuiIconButton-root')
      ) {
        handleDrawerClose()
      }
    }
    window.addEventListener('click', handleWindowClick)
    return () => {
      window.removeEventListener('click', handleWindowClick)
    }
  }, [])

  return (
    <>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        onClick={handleDrawerOpen}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper
        }}
        anchor="right"
        open={open}
        onClose={handleDrawerClose}
      >
        <div className={classes.drawerContainer}>
          <List>
            <ListItem button component="a" href="/">
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText disableTypography primary="Home" />
            </ListItem>
            <ListItem button component="a" href="/events">
              <ListItemIcon>
                <DomainVerificationIcon />
              </ListItemIcon>
              <ListItemText disableTypography primary="Events" />
            </ListItem>
            <ListItem button component="a" href="/workshops">
              <ListItemIcon>
                <StorefrontIcon />
              </ListItemIcon>
              <ListItemText disableTypography primary="Workshops" />
            </ListItem>
            <ListItem button component="a" href="/guest-lectures">
              <ListItemIcon>
                <LibraryBooksOutlinedIcon />
              </ListItemIcon>
              <ListItemText disableTypography primary="Guest Lectures" />
            </ListItem>
            <ListItem button component="a" href="https://linktr.ee/sensors2025">
              <ListItemIcon>
                <LibraryBooksOutlinedIcon />
              </ListItemIcon>
              <ListItemText disableTypography primary="Blog" />
            </ListItem>
            {/* {
              <ListItem button>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText disableTypography primary="Logout" />
              </ListItem>
            }
            {
              <ListItem
                button
                component="a"
                href={
                  getEnv({ key: 'REACT_APP_DISABLE_LOGIN' }) === 'true'
                    ? '/'
                    : '/login'
                }
              >
                <ListItemIcon>
                  <LoginIcon />
                </ListItemIcon>
                <ListItemText disableTypography primary="Login" />
              </ListItem>
            } */}
          </List>
        </div>
      </Drawer>
    </>
  )
}

export default NavDrawer
