import { Facebook, Instagram, LinkedIn, Twitter } from '@mui/icons-material'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { AppBar, Button, IconButton, Toolbar, Typography } from '@mui/material'
import { Box } from '@mui/system'

const Footer = () => {
  return (
    <AppBar position="static" color="transparent" enableColorOnDark>
      <Toolbar
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '10rem'
        }}
      >
        <div
          style={{
            backgroundColor: '#ffffff',
            width: '100%',
            maxWidth: '320px',
            height: '1px',
            marginTop: '12px',
            marginBottom: '24px'
          }}
        />
        <Typography variant="subtitle1" fontFamily="Lato" color="inherit">
          Made with
          <IconButton color="inherit">
            <FavoriteIcon />
          </IconButton>
          by Sensors WebOps
        </Typography>
        <div>
          <IconButton
            color="inherit"
            href="https://www.instagram.com/sensorsnitt/"
          >
            <Instagram />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.facebook.com/sensorsNITTrichy/"
          >
            <Facebook />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://in.linkedin.com/company/sensors-nitt"
          >
            <LinkedIn />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://twitter.com/officialsensors"
          >
            <Twitter />
          </IconButton>
        </div>
        <Box
          paddingY="8px"
          color="#aaaaaa"
          alignItems="center"
          textAlign="center"
        >
          <Button variant="text" color="inherit" href="/privacy-policy">
            Privacy Policy
          </Button>
          |
          <Button variant="text" color="inherit" href="/terms-and-conditions">
            Terms and Conditions
          </Button>
          |
          <Button
            variant="text"
            color="inherit"
            href="/refunds-and-cancellation"
          >
            Refunds and Cancellation
          </Button>
          |
          <Button variant="text" color="inherit" href="/contact">
            Contact
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Footer
