import React from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { Paper, Typography } from '@material-ui/core'
import { Box } from '@mui/material'
import { IContact } from '../../utils/pages'
import darkTheme from '../../theme/theme'
import ContactCard from './card'
import { ReactComponent as ContactHeader } from '../../assets/contact.svg'

interface ContactsProps {
  contacts: IContact[]
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start'
  },
  paper: {
    display: 'inline-block',
    margin: theme.spacing(0, 2),
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  sponsorImage: { height: '130px' },
  title: {
    maxHeight: '50px'
  }
}))

const Contacts: React.FC<ContactsProps> = ({ contacts }) => {
  const classes = useStyles()

  return (
    <ThemeProvider theme={darkTheme}>
      <Box className={classes.root}>
        <Typography variant="h3" align="center" gutterBottom>
          <ContactHeader style={{ height: '200px', width: '200px' }} />
        </Typography>
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          {contacts.map((contact) => (
            <ContactCard contact={contact} />
          ))}
        </div>
      </Box>
    </ThemeProvider>
  )
}

export default Contacts
