import { IPopup } from '../components/alert/popup'

export interface Props<T> {
  store: T
}

export const DEFAULT_POPUP: IPopup = {
  open: false,
  message: '',
  severity: 'error',
  onClose: () => {}
}
